import { gql } from "apollo-boost";

export const CUSTOMERS = gql`
  query customers($keyword: String, $id: String, $page: Int) {
    customers(keyword: $keyword, id: $id, page: $page) {
      id
      email
      firstName
      lastName
      status
      createdAt
      phoneNumber
      userType
    }
  }
`;

export const CLEANERS = gql`
  query cleaners($keyword: String, $id: String, $page: Int) {
    cleaners(keyword: $keyword, id: $id, page: $page) {
      id
      email
      firstName
      lastName
      phoneNumber
      createdAt
      status
    }
  }
`;
export const PROMOS = gql`
  query($page: Int) {
    promos(page: $page) {
      id
      promoCode
      promoDiscount
      promoDesc
    }
  }
`;

export const PROMO = gql`
  query promo($promoCode: String!) {
    promo(promoCode: $promoCode) {
      id
      promoCode
      promoDiscount
      promoDesc
    }
  }
`;

export const AREA_CLEANERS = gql`
  query area_cleaners($area: String!, $serviceType: String!) {
    area_cleaners(area: $area, serviceType: $serviceType) {
      phoneNumber
      cleaner {
        firstName
      }
    }
  }
`;

export const CLEANERINFO_USERID = gql`
  query cleanerInfo_userId($userId: ID!) {
    cleanerInfo_userId(userId: $userId) {
      phoneNumber
      cleaner {
        firstName
      }
    }
  }
`;

export const SIGN_IN_ADMIN = gql`
  mutation signInAdmin($email: String!, $password: String!) {
    signInAdmin(email: $email, password: $password) {
      token
    }
  }
`;
export const DELETE_PROMO = gql`
  mutation deletePromo($id: ID!) {
    deletePromo(id: $id) {
      success
    }
  }
`;

export const CREATE_PROMO = gql`
  mutation createPromo(
    $promoCode: String!
    $promoDiscount: Float!
    $promoDesc: String
  ) {
    createPromoCode(
      promoCode: $promoCode
      promoDiscount: $promoDiscount
      promoDesc: $promoDesc
    ) {
      id
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
  ) {
    signUp(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
    ) {
      token
    }
  }
`;

export const VERIFY_USER = gql`
  mutation verifyUser($verCode: String!) {
    verifyUser(verCode: $verCode) {
      id
    }
  }
`;

export const CHECK_VERCODE = gql`
  query checkVerCode($verCode: String!) {
    checkVerCode(verCode: $verCode) {
      email
    }
  }
`;

export const USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      userType
      status
      emailNotification
    }
  }
`;

export const DELETE_USER_BY_ADMIN = gql`
    mutation deleteUserByAdmin($id: ID!) {
      deleteUserByAdmin(id: $id)
    }
`;

export const CLEANER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      status
      emailNotification
      cleanerInfo {
        id
        phoneNumber
        alternatePhoneNumber
        street
        apartment
        city
        province
        postCode
        areas
        experience
        experience_des
        hoursPerWeek
        transportation
        workWeenkend
        workEvening
        workPets
        clean_des
        referenceName
        referenceEmail
        referencePhoneNumber
        referenceRelationship
        status
      }
    }
  }
`;

export const ME = gql`
  query {
    me {
      id
      email
      firstName
      lastName
      userType
      status
    }
  }
`;

export const ME_CLEANER_INFO = gql`
  query {
    me {
      email
      firstName
      lastName
      cleanerInfo {
        status
        phoneNumber
        alternatePhoneNumber
        street
        apartment
        city
        province
        postCode
        areas
        experience
        experience_des
        hoursPerWeek
        transportation
        workWeenkend
        workEvening
        workPets
        referenceName
        referenceEmail
        referencePhoneNumber
        referenceRelationship
      }
    }
  }
`;

export const UPDATE_CLEANER_INFO_ADMIN = gql`
  mutation updateCleanerInfoAdmin(
    $id: ID!
    $status: String!
    $phoneNumber: String!
    $alternatePhoneNumber: String
    $street: String!
    $apartment: String
    $city: String!
    $province: String!
    $postCode: String!
    $areas: [String]
    $experience: String!
    $experience_des: String!
    $clean_des: String!
    $hoursPerWeek: String!
    $transportation: String!
    $workWeenkend: String!
    $workEvening: String!
    $workPets: String!
    $referenceName: String
    $referenceEmail: String
    $referencePhoneNumber: String
    $referenceRelationship: String
  ) {
    updateCleanerInfoAdmin(
      id: $id
      status: $status
      phoneNumber: $phoneNumber
      alternatePhoneNumber: $alternatePhoneNumber
      street: $street
      apartment: $apartment
      city: $city
      province: $province
      postCode: $postCode
      areas: $areas
      experience: $experience
      experience_des: $experience_des
      clean_des: $clean_des
      hoursPerWeek: $hoursPerWeek
      transportation: $transportation
      workWeenkend: $workWeenkend
      workEvening: $workEvening
      workPets: $workPets
      referenceName: $referenceName
      referenceEmail: $referenceEmail
      referencePhoneNumber: $referencePhoneNumber
      referenceRelationship: $referenceRelationship
    ) {
      id
      status
    }
  }
`;

export const CLEANER_SIGN_UP = gql`
  mutation cleanerSignUp(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
  ) {
    cleanerSignUp(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
    ) {
      id
    }
  }
`;

export const CREATE_CLEANER_INFO = gql`
  mutation createCleanerInfo(
    $status: String!
    $phoneNumber: String!
    $alternatePhoneNumber: String
    $street: String!
    $apartment: String
    $city: String!
    $province: String!
    $postCode: String!
    $areas: [String]
    $experience: String!
    $experience_des: String!
    $clean_des: String!
    $hoursPerWeek: String!
    $transportation: String!
    $workWeenkend: String!
    $workEvening: String!
    $workPets: String!
    $referenceName: String
    $referenceEmail: String
    $referencePhoneNumber: String
    $referenceRelationship: String
  ) {
    createCleanerInfo(
      status: $status
      phoneNumber: $phoneNumber
      alternatePhoneNumber: $alternatePhoneNumber
      street: $street
      apartment: $apartment
      city: $city
      province: $province
      postCode: $postCode
      areas: $areas
      experience: $experience
      experience_des: $experience_des
      clean_des: $clean_des
      hoursPerWeek: $hoursPerWeek
      transportation: $transportation
      workWeenkend: $workWeenkend
      workEvening: $workEvening
      workPets: $workPets
      referenceName: $referenceName
      referenceEmail: $referenceEmail
      referencePhoneNumber: $referencePhoneNumber
      referenceRelationship: $referenceRelationship
    ) {
      status
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation updateUserInfo(
    $id: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $emailNotification: Boolean!
  ) {
    updateUserInfo(
      id: $id
      email: $email
      firstName: $firstName
      lastName: $lastName
      emailNotification: $emailNotification
    ) {
      id
      email
      firstName
      lastName
      emailNotification
    }
  }
`;

export const UPDATE_USER_ADMIN = gql`
  mutation updateUserAdmin(
    $id: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $emailNotification: Boolean!
  ) {
    updateUserAdmin(
      id: $id
      email: $email
      firstName: $firstName
      lastName: $lastName
      emailNotification: $emailNotification
    ) {
      id
      email
      firstName
      lastName
      emailNotification
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!, $newPassword: String!) {
    updatePassword(password: $password, newPassword: $newPassword) {
      id
    }
  }
`;

export const CLEANERINFO = gql`
  query cleanerInfo($userId: ID!) {
    cleanerInfo(userId: $userId) {
      phoneNumber
      cleaner {
        firstName
      }
    }
  }
`;

export const SETTING = gql`
  query setting($id: ID!) {
    setting(id: $id) {
      sms
    }
  }
`;

export const UPDATE_SETTING = gql`
  mutation updateSetting($id: ID!, $sms: String!) {
    updateSetting(id: $id, sms: $sms) {
      sms
    }
  }
`;

export const getUserInfo = `{
  me {
    id
    email
    firstName
    lastName
    userType
    emailNotification
    status

  }
}`;
