import React, { useState } from "react";
import { Mutation } from "react-apollo";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Layout from "../components/Layout";
import { CREATE_PROMO, PROMOS, DELETE_PROMO } from "../queries/user";
import * as queryString from "query-string";

const Inbox = props => {
  const queryParams = queryString.parse(props.location.search);
  if (!queryParams.page) {
    queryParams.page = 1;
  }
  let { data, loading, error } = useQuery(PROMOS, {
    variables: { page: parseInt(queryParams.page) }
  });
  const [deletePromo, mutationRes] = useMutation(DELETE_PROMO);

  const [promoCode, setPromoCode] = useState("");
  const [promoDiscount, setPromoDiscount] = useState("");
  const [promoDesc, setPromoDesc] = useState("");
  // const [promoCodeUseTime, setPromoCodeUseTime] = useState("");

  function onPromoCodeInput(e) {
    setPromoCode(e.target.value);
    console.log(promoCode);
  }
  function onPromoDiscountInput(e) {
    setPromoDiscount(e.target.value);
    console.log(promoDiscount);
  }
  function onPromoDescInput(e) {
    setPromoDesc(e.target.value);
    console.log(promoDesc);
  }
  function makeCode(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function genRandomCode() {
    const randomCode = makeCode(6);
    setPromoCode(randomCode);
  }
  function handleDeletePromo(id) {
    deletePromo({ variables: { id } }).then(res => {
      console.log(res);
      window.location.reload();
      return res;
    });
  }
  function savePromoCode(e, createPromo) {
    e.preventDefault();
    setPromoDiscount(parseFloat(promoDiscount));
    console.log(promoCode, promoDiscount, promoDesc);
    createPromo({
      variables: {
        promoCode,
        promoDiscount: parseFloat(promoDiscount),
        promoDesc
      }
    });
  }

  return (
    <Layout>
      <div className="container-fluid">
        <h1>Promotion</h1>
        <div className="promo-section">
          <Mutation
            mutation={CREATE_PROMO}
            onCompleted={data => {
              console.log(data);
              window.location.reload();
            }}
            onError={error => {
              console.log(error);
            }}
          >
            {createPromo => (
              <form onSubmit={e => savePromoCode(e, createPromo)}>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Promotion Name:</span>
                  </div>
                  <input
                    id="promoCodeName"
                    name="promoCodeName"
                    value={promoDesc}
                    onChange={onPromoDescInput}
                    type="text"
                    aria-label="promo code name"
                    className="form-control"
                    placeholder="Describe the promo code(optional)"
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      Discount Percentage (%):
                    </span>
                  </div>
                  <input
                    required
                    id="codeDiscount"
                    name="codeDiscount"
                    value={promoDiscount}
                    onChange={onPromoDiscountInput}
                    type="number"
                    placeholder="Promo code discount in %"
                    aria-label="discount percentage"
                    className="form-control"
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Promo Code:</span>
                  </div>
                  <input
                    id="promoCode"
                    name="promoCode"
                    value={promoCode}
                    onChange={onPromoCodeInput}
                    type="text"
                    aria-label="promo code"
                    className="form-control"
                    placeholder="Enter promo code/Click random Generate"
                    required
                  />
                </div>
                <div className="input-group-append" id="button-addon4">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={genRandomCode}
                  >
                    Generate Random Code
                  </button>
                  <button className="btn btn-outline-primary" type="submit">
                    Save Code
                  </button>
                </div>
              </form>
            )}
          </Mutation>
          {loading && <p>LOADING Promo codes</p>}
          {error && <p>Fetch Promo code Error</p>}
          {data && (
            <div>
              {data.promos.length ? (
                <table className="table table-hover text-center">
                  <thead>
                    <tr>
                      <th scope="col">Promo Name</th>
                      <th scope="col">Promo Discount</th>
                      <th scope="col">Promo Code</th>
                      <th scope="col">Delete Promo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.promos &&
                      data.promos.map(promo => (
                        <tr key={promo.id}>
                          <td>{promo.promoDesc}</td>
                          <td>{promo.promoDiscount}% OFF</td>
                          <td>{promo.promoCode}</td>
                          <td>
                            <button
                              className="btn"
                              onClick={e => {
                                handleDeletePromo(promo.id);
                              }}
                            >
                              <i className="fas fa-trash fa-2x"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                  <p>No more Promo code</p>
                )}

              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li className="page-item">
                    <button
                      hidden={queryParams.page <= 1}
                      onClick={() => {
                        if (!queryParams.page) {
                          queryParams.page = 1;
                        }
                        const nextPage = +queryParams.page - 1;
                        props.history.push({ search: "?page=" + nextPage });
                      }}
                      className="page-link"
                    >
                      Previous
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      hidden={data.promos.length !== 10}
                      onClick={() => {
                        if (!queryParams.page) {
                          queryParams.page = 1;
                        }
                        const nextPage = +queryParams.page + 1;
                        props.history.push({ search: "?page=" + nextPage });
                      }}
                      className="page-link"
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default Inbox;
