import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../static/datePicker.css';

const DatePickerField = ({ name, value, onChange }) => {
  return (
    <DatePicker
      selected={(value && new Date(value)) || null}
      onChange={(val) => {
        onChange(name, val.toString().slice(0, 21));
      }}
      className='is-invalid'
      placeholderText='Click to select a date and time'
      showTimeSelect
      timeFormat='HH:mm'
      timeIntervals={30}
      timeCaption='time'
      dateFormat='MMMM d, yyyy h:mm aa'
    />
  );
};

export default DatePickerField;
