import { USER } from "../queries/user";
import React from "react";
import Layout from "../components/Layout";
import { useQuery } from "@apollo/react-hooks";
import CustomerDetailForm from "../components/CustomerDetailForm";

const Customer = props => {
  const { data, loading, error } = useQuery(USER, {
    variables: { id: props.match.params.id }
  });

  return (
    <Layout>
      {loading && <p style={{ minHeight: "100vh" }}> Loading </p>}
      {error && <p style={{ minHeight: "100vh" }}>Error</p>}
      {data && (
        <div className="container mt-4 mb-5" style={{ minHeight: "100vh" }}>
          {data.user && (
            <div
              style={{
                border: "2px solid #8492a6",
                borderRadius: "25px"
              }}
            >
              <CustomerDetailForm user={data.user} />
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};
export default Customer;
