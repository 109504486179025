import { Mutation } from "react-apollo";
import { SIGN_IN_ADMIN } from "../queries/user";
import React, { useState, useContext } from "react";
import { UserContext } from "./context/UserContext";
import { withRouter } from "react-router";
const SignInForm = props => {
  const { adminToken, setAdminToken } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  return (
    <Mutation
      mutation={SIGN_IN_ADMIN}
      onCompleted={data => {
        const { token } = data.signInAdmin;
        if (token) {
          setAdminToken(token);
          setError("");
          localStorage.setItem("adminToken", token);
          props.history.push("/");
        }
      }}
      onError={error => {
        setError(
          error.graphQLErrors ? error.graphQLErrors[0].message : error.message
        );
      }}
    >
      {signIn => (
        <div>
          <form
            onSubmit={e => {
              e.preventDefault();
              signIn({
                variables: { email, password }
              });
            }}
          >
            <h1 className="text-center mb-3">
              <i className="fas fa-sign-in-alt" /> Login
            </h1>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                placeholder="Enter Email"
                onChange={handleEmailChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                className="form-control"
                placeholder="Enter Password"
                onChange={handlePasswordChange}
              />
            </div>
            <button type="submit" className="btn btn-primary btn-block">
              Login
            </button>
            {error && (
              <p className="lead mt-4" style={{ color: "red" }}>
                {error}
              </p>
            )}
          </form>
        </div>
      )}
    </Mutation>
  );
};

export default withRouter(SignInForm);
