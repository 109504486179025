import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { UserContext } from "./context/UserContext";
const Navbar = props => {
  const { adminToken, setAdminToken } = useContext(UserContext);
  const handleSignOut = () => {
    setAdminToken(null);
    localStorage.clear("adminToken");
    props.history.push("/signin");
  };

  return (
    <nav className="navbar navbar-light bg-light">
      <div className="dropdown">
        <button
          className="btn navbar-toggle"
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="dropdown-menu text-center"
          aria-labelledby="dropdownMenu2"
        >
          <Link to={`/dashboard/orders`} className="text-center">
            Orders
          </Link>
          <div className="dropdown-divider"></div>
          <Link to={`/dashboard/customers`} className="text-center">
            Customers
          </Link>
          <div className="dropdown-divider"></div>
          <Link to={`/dashboard/cleaners`} className="text-center">
            Cleaners
          </Link>
          <div className="dropdown-divider"></div>
          <Link to={`/dashboard/promo`} className="text-center">
            Promo
          </Link>
          <div className="dropdown-divider"></div>
          <Link to={`/dashboard/setting`} className="text-center">
            Setting
          </Link>
        </div>
      </div>
      <a className="navbar-brand mx-auto" href="/">
        <img
          className="logo"
          src="../../../logo.png"
          style={{ width: "100px" }}
        ></img>
      </a>
      <div className="dropdown ">
        <button
          className="btn navbar-toggle"
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fas fa-user-tie fa-2x"></i>
        </button>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenu2"
        >
          <a className="dropdown-item " onClick={handleSignOut}>
            Sign Out
          </a>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
