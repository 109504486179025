import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ORDER } from '../queries/order';
import Layout from '../components/Layout';
import OrderDetailForm from '../components/OrderDetailForm';
import OrderSummaryCard from '../components/OrderSummaryCard';
import { sendOrderMsgGroup } from '../lib/helper';
import {
  postWithAuth,
  sendOrderMsgToAssignedStaff,
  sendText,
} from '../lib/helper';
import {
  CANCEL_ORDER,
  COMPLETED_ORDER,
  PENDING_ORDER,
  EMAIL_INVOICE,
} from '../queries/order';
import PicturesCard from '../components/PicturesCard';
import OrderChangeModal from '../components/OrderChangeModal';
const Order = (props) => {
  const { data, loading, error } = useQuery(ORDER, {
    variables: { id: props.match.params.id },
  });
  const [changeOrderError, setChangeOrderError] = useState('');
  const [bookingSummary, setBookingSummary] = useState({});
  const [formValues, setFormValues] = useState({
    orderDetailForm: {
      id: props.match.params.id,
    },
  });

  useEffect(() => {
    if (data && data.order) {
      setBookingSummary({
        subTotal: data.order.subTotal,
        tax: data.order.tax,
        total: data.order.total,
        outstanding: data.order.outstanding,
      });
      setFormValues({ orderDetailForm: data.order });
    }
  }, [data]);

  const handleOrderDetailFormChange = (values) => {
    setFormValues({
      ...formValues,
      orderDetailForm: values,
    });
  };

  const handleCancelOrder = () => {
    postWithAuth(CANCEL_ORDER, {
      id: data.order.id,
      outstanding: formValues.orderDetailForm.outstanding,
    }).then((res) => {
      if (res.data.data.cancelOrder) {
        if (data.order.cleaner) {
          sendOrderMsgToAssignedStaff(
            res.data.data.cancelOrder.cleaner.id,
            data.order.id,
            'DELETE'
          ).then((res) => {
            window.location.reload();
          });
        } else {
          window.location.reload();
        }
      } else {
        if (res.data.errors[0].message) {
          setChangeOrderError(res.data.errors[0].message);
        }
      }
    });
  };

  const handleCompleteOrder = () => {
    postWithAuth(COMPLETED_ORDER, {
      id: data.order.id,
    }).then((res) => {
      if (res.data.data.completeOrder) {
        if (data.order.cleaner) {
          sendOrderMsgToAssignedStaff(
            res.data.data.completeOrder.cleaner.id,
            data.order.id,
            'COMPLETED'
          ).then((res) => {
            window.location.reload();
          });
        } else {
          window.location.reload();
        }
      } else {
        if (res.data.errors[0].message) {
          setChangeOrderError(res.data.errors[0].message);
        }
      }
    });
  };

  const handlePendingOrder = () => {
    let cleaner;
    if (data.order.cleaner) {
      cleaner = data.order.cleaner;
    }

    postWithAuth(PENDING_ORDER, {
      id: data.order.id,
    }).then((res) => {
      if (res.data.data.pendingOrder) {
        if (cleaner) {
          sendOrderMsgToAssignedStaff(
            cleaner.id,
            data.order.id,
            'PENDING'
          ).then((res) => {
            window.location.reload();
          });
        } else {
          window.location.reload();
        }
      } else {
        if (res.data.errors[0].message) {
          setChangeOrderError(res.data.errors[0].message);
        }
      }
    });
  };

  const handleBroadcast = async () => {
    if (data.order.status === 'PENDING') {
      await sendOrderMsgGroup(
        data.order.id,
        data.order.city,
        data.order.serviceType,
        'NEW'
      );
      alert('Order broadcast successfully');
    } else {
      alert('Order is not in pending status');
    }
  };

  const handleEmailInvoice = () => {
    postWithAuth(EMAIL_INVOICE, {
      id: data.order.id,
    }).then((res) => {
      if (res.data.data.emailInvoice) {
        alert('Invoice Email sent');
      } else {
        if (res.data.errors[0].message) {
          setChangeOrderError(res.data.errors[0].message);
        }
      }
    });
  };
  return (
    <Layout>
      <div className='container mt-4 mb-5' style={{ minHeight: '100vh' }}>
        {loading && <p> Loading </p>}
        {error && <p>Error</p>}
        {data && (
          <div className='row'>
            <div
              className='col-md-8 order-md-1'
              style={{
                border: '2px solid #8492a6',
                borderRadius: '25px',
              }}
            >
              <OrderDetailForm
                order={data.order}
                onChange={handleOrderDetailFormChange}
                // handlePriceChange={setBookingSummary}
                summary={bookingSummary}
              />
            </div>
            <div className='col-md-4 order-md-2 mb-4'>
              <OrderSummaryCard
                subTotal={bookingSummary.subTotal}
                tax={bookingSummary.tax}
                total={bookingSummary.total}
                outstanding={bookingSummary.outstanding}
                street={formValues.orderDetailForm.street}
                city={formValues.orderDetailForm.city}
                status={formValues.orderDetailForm.status}
                discount={data.order.discount}
                discountValue={data.order.discountValue}
              />

              <OrderChangeModal
                title={'CANCEL'}
                des={'cancel the order'}
                handleSubmit={handleCancelOrder}
              />

              <OrderChangeModal
                title={'COMPLETE'}
                des={'complete the order'}
                handleSubmit={handleCompleteOrder}
              />

              <OrderChangeModal
                title={'PENDING'}
                des={'pending the order'}
                handleSubmit={handlePendingOrder}
              />

              <OrderChangeModal
                title={'NOTIFY ALL CLEANERS'}
                des={'notify all cleaners'}
                handleSubmit={handleBroadcast}
              />
              <OrderChangeModal
                title={'EMAIL INVOICE'}
                des={'email invoice'}
                handleSubmit={handleEmailInvoice}
              />

              {changeOrderError && (
                <p className='lead mt-4' style={{ color: 'red' }}>
                  {changeOrderError}
                </p>
              )}
              {data.order.picture.length > 0 && (
                <div
                  className='formContainer mt-3'
                  style={{ border: '2px solid #8492a6', borderRadius: '25px' }}
                >
                  <PicturesCard pictures={data.order.picture} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
export default Order;
