import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const OrderChangeModal = (props) => {
  const [modal, setModal] = useState(false);

  const toggle = (value) => {
    setModal(!modal);
    if (value === 'confirm') {
      props.handleSubmit();
    }
  };

  return (
    <div>
      <div
        className='formContainer mt-3'
        style={{ border: '2px solid #8492a6', borderRadius: '25px' }}
      >
        <div className='p-1 d-flex justify-content-between align-items-center'>
          <button
            className='btn mx-auto bdbtn'
            style={{
              color: '#3ecad2',
              outline: 'none',
              boxShadow: 'none',
            }}
            onClick={toggle}
          >
            <h4 className=' '>{props.title}</h4>
          </button>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <p>Please confirm the action: {props.des}</p>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={toggle}>
            No
          </Button>
          <Button
            color='primary'
            value='confirm'
            onClick={() => toggle('confirm')}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OrderChangeModal;
