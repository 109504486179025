import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Orders from "./pages/Orders";
import Customers from "./pages/Customers";
import Cleaners from "./pages/Cleaners";
import Promo from "./pages/Promo";
import Setting from "./pages/Setting";
import Order from "./pages/Order";
import Inbox from "./pages/Inbox";
import Customer from "./pages/Customer";
import Cleaner from "./pages/Cleaner";
import SignIn from "./pages/SignIn";
import { UserContext } from "../src/components/context/UserContext";
import PrivateRoute from "../src/components/PrivateRoute";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { adminToken: localStorage.getItem("adminToken") };
  }

  setAdminToken = (adminToken) => {
    this.setState({ adminToken });
  };

  render() {
    const App = () => (
      <div>
        <UserContext.Provider
          value={{
            adminToken: this.state.adminToken,
            setAdminToken: this.setAdminToken,
          }}
        >
          <Switch>
            <PrivateRoute exact path="/" component={Orders} />
            <PrivateRoute path="/dashboard/orders" component={Orders} />
            <PrivateRoute path="/dashboard/customers" component={Customers} />
            <PrivateRoute path="/dashboard/cleaners" component={Cleaners} />
            <PrivateRoute path="/dashboard/promo" component={Promo} />
            <PrivateRoute path="/dashboard/setting" component={Setting} />
            <PrivateRoute path="/dashboard/inbox" component={Inbox} />
            <PrivateRoute path="/dashboard/order/:id" component={Order} />
            <PrivateRoute path="/dashboard/customer/:id" component={Customer} />
            <PrivateRoute path="/dashboard/cleaner/:id" component={Cleaner} />
            <Route path="/signin" component={SignIn} />
          </Switch>
        </UserContext.Provider>
      </div>
    );
    return (
      <Switch>
        <App />
      </Switch>
    );
  }
}

export default App;
