import { useState } from 'react';
import { Mutation } from 'react-apollo';
import { withFormik } from 'formik';
import { UPDATE_USER_INFO } from '../queries/user';
import React from 'react';
import Alert from './Alert';

const CustomerDetailForm = (props) => {
  const { values, handleChange, onChange, setFieldValue } = props;
  const [done, setDone] = useState(false);
  const [error, setError] = useState('');
  console.log(values);
  return (
    <Mutation
      mutation={UPDATE_USER_INFO}
      onCompleted={(data) => {
        setDone(true);
        setError('');
      }}
      onError={(error) => {
        console.log(error);
        setDone(false);
        setError(
          error.graphQLErrors ? error.graphQLErrors[0].message : error.message
        );
      }}
    >
      {(updateUserInfo) => (
        <div className='p-4'>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              console.log('heres');
              updateUserInfo({
                variables: values,
              });
            }}
          >
            <h1>Profile</h1>
            {done && !error && <Alert type='success' des='Profile Updated' />}
            {error && <Alert type='danger' des={error} />}
            <div className='mt-5'>
              <div className='row'>
                <div className='col-md-3 mb-3'>
                  <p>Name</p>
                </div>
                <div className='col-md-3 mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    required
                    pattern='[A-Za-z]'
                    name='firstName'
                    defaultValue={values.firstName}
                    values={values.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className='col-md-3 mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    required
                    pattern='[A-Za-z]'
                    name='lastName'
                    defaultValue={values.lastName}
                    values={values.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-3 mb-3'>
                  <p>Email</p>
                </div>
                <div className='col-md-3 mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    name='email'
                    defaultValue={values.email}
                    values={values.email}
                    onChange={handleChange}
                  />
                </div>
                <div className='col-md-3 mb-3'>
                  {props.user.userType === 'CUSTOMER' && (
                    <div className='form-check'>
                      <label className='form-check-label'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          name='emailNotification'
                          checked={values.emailNotification}
                          value={values.emailNotification}
                          onChange={handleChange}
                        />
                        Consent to send you Email notification
                      </label>
                    </div>
                  )}
                </div>
                <div className='col-md-3 mb-3'>
                  <button
                    type='submit'
                    className='btn btn-block'
                    style={{
                      backgroundColor: '#3ecad2',
                      color: 'white',
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className='col-md-12 lead'>
                Please note, re-verify email is needed if you change your email
              </div>
            </div>
          </form>
        </div>
      )}
    </Mutation>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const { email, firstName, lastName, emailNotification, id } = props.user;
    return {
      id: id,
      email: email,
      firstName: firstName,
      lastName: lastName,
      emailNotification: emailNotification === true ? true : false,
    };
  },
})(CustomerDetailForm);
