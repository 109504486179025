import { Mutation } from 'react-apollo';
import { FieldArray, Formik } from 'formik';
import { UPDATE_CLEANER_INFO_ADMIN } from '../queries/user';
import { CLEANER_STATUS, CLEANER_CITY_TYPE } from '../static/form';
import React, { useState } from 'react';
import { sendOrderMsgToAssignedStaff } from '../lib/helper';

const CleanerInfoForm = (props) => {
  const { user, handleChange, onChange, setFieldValue } = props;
  const [error, setError] = useState('');
  const [done, setDone] = useState(false);

  return (
    <div>
      <Mutation
        mutation={UPDATE_CLEANER_INFO_ADMIN}
        onCompleted={(data) => {
          if (
            user.status === 'APPLICATION_SUBMITTED' &&
            data.updateCleanerInfoAdmin.status === 'APPROVED'
          ) {
            sendOrderMsgToAssignedStaff(user.id, user.id, 'PLAIN_TEXT')
              .then((res) => {
                setDone(true);
                setError('');
              })
              .catch((e) => {
                setDone(false);
                setError(
                  'Cleaner information updated, but failed to notify the cleaner'
                );
              });
          } else {
            setDone(true);
            setError('');
          }
        }}
        onError={(error) => {
          setError(
            error.graphQLErrors ? error.graphQLErrors[0].message : error.message
          );
        }}
      >
        {(updateCleanerInfoAdmin) => (
          <div className='p-4'>
            <h4 className='mb-3'>Update Information</h4>

            <Formik
              enableReinitialize
              initialValues={{
                id: user.cleanerInfo.id,
                phoneNumber: user.cleanerInfo.phoneNumber,
                alternatePhoneNumber: user.cleanerInfo.alternatePhoneNumber,
                apartment: user.cleanerInfo.apartment,
                street: user.cleanerInfo.street,
                city: user.cleanerInfo.city,
                province: user.cleanerInfo.province,
                experience: user.cleanerInfo.experience,
                experience_des: user.cleanerInfo.experience_des,
                clean_des: user.cleanerInfo.clean_des,
                hoursPerWeek: user.cleanerInfo.hoursPerWeek,
                transportation: user.cleanerInfo.transportation,
                workEvening: user.cleanerInfo.workEvening,
                workPets: user.cleanerInfo.workPets,
                workWeenkend: user.cleanerInfo.workWeenkend,
                postCode: user.cleanerInfo.postCode,
                areas: user.cleanerInfo.areas,
                referenceName: user.cleanerInfo.referenceName,
                referenceEmail: user.cleanerInfo.referenceEmail,
                referencePhoneNumber: user.cleanerInfo.referencePhoneNumber,
                referenceRelationship: user.cleanerInfo.referenceRelationship,
                status: user.status,
              }}
              onSubmit={async (values, actions) => {
                actions.setSubmitting(false);
                updateCleanerInfoAdmin({ variables: values });
              }}
              render={({
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <p> CONTACT INFORMATION</p>
                  <div className='mb-3'>
                    <p>Phone Number</p>
                    <div className='row'>
                      <div className='col-md-4 mb-3'>
                        <label htmlFor='phoneNumber'>Cell Number</label>
                        <input
                          type='tel'
                          className='form-control'
                          name='phoneNumber'
                          value={values.phoneNumber}
                          onChange={handleChange}
                        />
                      </div>
                      <div className='col-md-4 mb-3'>
                        <label htmlFor='alternatePhoneNumber'>
                          Alternative Number
                        </label>
                        <input
                          type='text'
                          pattern='[0-9]{10}'
                          className='form-control'
                          name='alternatePhoneNumber'
                          value={values.alternatePhoneNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='mb-3'>
                    <p>Address</p>
                    <div className='row'>
                      <div className='col-md-4 mb-3'>
                        <label htmlFor='street'>Street</label>
                        <input
                          type='text'
                          className='form-control'
                          name='street'
                          value={values.street}
                          onChange={handleChange}
                        />
                      </div>

                      <div className='col-md-4 mb-3'>
                        <label htmlFor='apartment'>Unit Number</label>
                        <input
                          type='text'
                          className='form-control'
                          name='apartment'
                          value={values.apartment}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-4 mb-3'>
                        <label htmlFor='city'>City</label>

                        <select
                          className='custom-select d-block w-100'
                          name='city'
                          value={values.city}
                          onChange={handleChange}
                        >
                          <option value='' label='Please Choose' />
                          {CLEANER_CITY_TYPE.map((city) => {
                            return (
                              <option key={city.id} value={city.name}>
                                {city.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className='col-md-4 mb-3'>
                        <label htmlFor='province'>Province</label>
                        <input
                          type='text'
                          className='form-control'
                          required
                          disabled
                          name='province'
                          value={values.province}
                          onChange={handleChange}
                        />
                      </div>

                      <div className='col-md-4 mb-3'>
                        <label htmlFor='postCode'>PostCode</label>
                        <input
                          type='text'
                          className='form-control'
                          name='postCode'
                          value={values.postCode.toUpperCase()}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className='mb-4' />
                  <div className='mb-3'>
                    <p>Experience</p>
                    <p>Current and Previous Cleaning Experience</p>
                    <label htmlFor='experience'>
                      How many years of residential experience?
                    </label>
                    <div className='input-group'>
                      <select
                        className='custom-select d-block w-100'
                        name='experience'
                        value={values.experience}
                        onChange={handleChange}
                      >
                        <option value='' label='Please Choose' />
                        <option value='none'>None</option>
                        <option value='1-6m'>1 to 6 months</option>
                        <option value='6-1y'>6 months to 1 year</option>
                        <option value='1-2y'>1 to 2 years</option>
                        <option value='2-5y'>2 to 5 years</option>
                        <option value='5y+'>More than 5 years</option>
                      </select>
                    </div>
                    <div className='mt-4'>
                      <label>Describe your cleaning experience</label>
                      <div className='input-group'>
                        <textarea
                          className='form-control'
                          rows='5'
                          placeholder='Type your message here'
                          name='experience_des'
                          value={values.experience_des}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className='mt-4'>
                      <label>Describe how you would clean a house?</label>
                      <div className='input-group'>
                        <textarea
                          className='form-control'
                          rows='5'
                          placeholder='Type your message here'
                          name='clean_des'
                          value={values.clean_des}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className='mb-4' />
                  <div className='mb-3'>
                    <p> AVAILABILITY & PREFERENCE</p>
                    <FieldArray
                      name='areas'
                      render={(arrayHelpers) => (
                        <div>
                          {CLEANER_CITY_TYPE.map((city) => (
                            <div key={city.id}>
                              <label>
                                <input
                                  name='areas'
                                  type='checkbox'
                                  value={city.id}
                                  checked={values.areas.includes(city.id)}
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      arrayHelpers.push(city.id);
                                    else {
                                      const idx = values.areas.indexOf(city.id);
                                      arrayHelpers.remove(idx);
                                    }
                                  }}
                                />
                                {city.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    />
                    <div className='row'>
                      <div className='col-md-6 mb-3'>
                        <label htmlFor='hoursPerWeek'>
                          How many hours do you prefer to work per week?
                        </label>
                        <div className='input-group'>
                          <select
                            className='custom-select d-block w-100'
                            name='hoursPerWeek'
                            value={values.hoursPerWeek}
                            onChange={handleChange}
                          >
                            <option value='' label='Please Choose' />
                            <option value='1-5h'>1 to 5 hours / week</option>
                            <option value='6-10h'>6 to 10 hours / week</option>
                            <option value='1-2h'>11 to 20 hours / week</option>
                            <option value='21-30h'>
                              21 to 30 hours / week
                            </option>
                            <option value='31h+'>31+ hours / week</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label htmlFor='transportation'>
                          How will you travel to and from jobs?
                        </label>
                        <div className='input-group'>
                          <select
                            className='custom-select d-block w-100'
                            name='transportation'
                            value={values.transportation}
                            onChange={handleChange}
                          >
                            <option value='' label='Please Choose' />
                            <option value='public_transit'>
                              Public Transportation
                            </option>
                            <option value='vehicle'>Vehicle</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label htmlFor='workWeenkend'>
                          Are you available to work weekends?
                        </label>
                        <div className='input-group'>
                          <select
                            className='custom-select d-block w-100'
                            name='workWeenkend'
                            value={values.workWeenkend}
                            onChange={handleChange}
                          >
                            <option value='' label='Please Choose' />
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label htmlFor='workEvening'>
                          Are you available to work evenings?
                        </label>
                        <div className='input-group'>
                          <select
                            className='custom-select d-block w-100'
                            name='workEvening'
                            value={values.workEvening}
                            onChange={handleChange}
                          >
                            <option value='' label='Please Choose' />
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label htmlFor='workPets'>
                          Do you have issues working with pets?
                        </label>
                        <div className='input-group'>
                          <select
                            className='custom-select d-block w-100'
                            name='workPets'
                            value={values.workPets}
                            onChange={handleChange}
                          >
                            <option value='' label='Please Choose' />
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className='mb-4' />
                  <div className='mb-3'>
                    <p>REFERENCE</p>
                    <p>please provide us with a reference</p>
                    <div className='row'>
                      <div className='col-md-6 mb-3'>
                        <label htmlFor='referenceName'>Name of Reference</label>
                        <input
                          type='text'
                          className='form-control'
                          name='referenceName'
                          placeholder='Name'
                          value={values.referenceName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label htmlFor='referenceEmail'>Email</label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Email'
                          name='referenceEmail'
                          value={values.referenceEmail}
                          onChange={handleChange}
                        />
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label htmlFor='referencePhoneNumber'>
                          Contact Number
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Contact Number'
                          name='referencePhoneNumber'
                          value={values.referencePhoneNumber}
                          onChange={handleChange}
                        />
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label htmlFor='referenceRelationship'>
                          How do you know this reference?
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='throught work? personal?'
                          name='referenceRelationship'
                          value={values.referenceRelationship}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label htmlFor='referenceRelationship'>
                      Set Cleaner Status
                    </label>

                    <select
                      className='custom-select d-block w-100'
                      name='status'
                      value={values.status}
                      onChange={handleChange}
                    >
                      <option value='' label='Please Choose' />
                      {CLEANER_STATUS.map((status) => {
                        return (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <hr className='mb-4' />
                  <div className='mb-3'>
                    <button
                      className='btn submit-btn btn-block'
                      type='submit'
                      style={{
                        backgroundColor: '#3ecad2',
                        color: 'white',
                      }}
                    >
                      CONFIRM CHANGE
                    </button>
                  </div>
                  {error && (
                    <p className='lead mt-4' style={{ color: 'red' }}>
                      {error}
                    </p>
                  )}
                  {done && (
                    <div className='alert alert-dismissible alert-info'>
                      <strong>Information Updated</strong>
                    </div>
                  )}
                </form>
              )}
            />
          </div>
        )}
      </Mutation>
    </div>
  );
};
export default CleanerInfoForm;
