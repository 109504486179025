import { CLEANER } from '../queries/user';
import React from 'react';
import Layout from '../components/Layout';
import { useQuery } from '@apollo/react-hooks';
import CleanerDetailForm from '../components/CleanerDetailForm';
import CustomerDetailFrom from '../components/CustomerDetailForm';
const Cleaner = (props) => {
  const { data, loading, error } = useQuery(CLEANER, {
    variables: { id: props.match.params.id },
  });

  return (
    <Layout>
      {loading && <p style={{ minHeight: '100vh' }}> Loading </p>}
      {error && <p style={{ minHeight: '100vh' }}>Error</p>}
      {data && (
        <div className='container mt-4 mb-5' style={{ minHeight: '100vh' }}>
          {data.user && (
            <div
              style={{
                border: '2px solid #8492a6',
                borderRadius: '25px',
              }}
            >
              <CustomerDetailFrom user={data.user} />
              {data.user.cleanerInfo && <CleanerDetailForm user={data.user} />}
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};
export default Cleaner;
