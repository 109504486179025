export const ExtraServicesType = [
  // { id: 'InsideOven', name: 'Inside Oven' },
  { id: 'InsideCabinets', name: 'Inside Cabinets' },
  { id: 'InteriorWindows', name: 'Interior Windows' },
  { id: 'FoggingServices', name: 'Disinfectant Fogging' },
];

export const HomeAccessType = [
  { id: 'customer-open', name: 'I will be there' },
  { id: 'keycafe', name: 'I will set up a Keycafe' },
  { id: 'notes', name: 'See notes below' },
];

export const CityType = [
  {
    id: 'Vancouver',
    name: 'Vancouver',
  },
  {
    id: 'Richmond',
    name: 'Richmond',
  },
  {
    id: 'Burnaby',
    name: 'Burnaby',
  },
  {
    id: 'Coquitlam',
    name: 'Coquitlam',
  },
  {
    id: 'Surrey',
    name: 'Surrey',
  },
  {
    id: 'Delta',
    name: 'Delta',
  },
];

export const CLEANER_CITY_TYPE = [
  {
    id: 'Vancouver',
    name: 'Vancouver',
  },
  {
    id: 'Richmond',
    name: 'Richmond',
  },
  {
    id: 'Burnaby',
    name: 'Burnaby',
  },
  {
    id: 'Coquitlam',
    name: 'Coquitlam',
  },
  {
    id: 'Surrey',
    name: 'Surrey',
  },
  {
    id: 'Delta',
    name: 'Delta',
  },

  {
    id: 'WestVancouver',
    name: 'West Vancouver',
  },
  {
    id: 'NorthVancouver',
    name: 'North Vancouver',
  },
  {
    id: 'NewWestminster',
    name: 'New Westminster',
  },
  {
    id: 'WhiteRock',
    name: 'White Rock',
  },
  {
    id: 'Langley',
    name: 'Langley',
  },
];

export const HOURS_SELECTION = [
  3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5,
  12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5,
];

export const CLEANER_STATUS = ['APPLICATION_SUBMITTED', 'APPROVED'];
