import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { withFormik, FieldArray } from "formik";
import { Mutation } from "react-apollo";
import { HomeAccessType, CityType, HOURS_SELECTION } from "../static/form";
import { PRICE_LIST } from "../static/priceList";
import { ExtraServicesType } from "../static/form.js";
import DatePicker from "../components/DatePicker";
// import 'react-datepicker/dist/react-datepicker.css';
import { UPDATE_ORDER_ADMIN, ASSIGN_ORDER_ADMIN } from "../queries/order";
import { calculateUpdatedOrderAmount } from "../lib/calculate";
import { postWithAuth, sendOrderMsgToAssignedStaff } from "../lib/helper";
import * as moment from "moment";
import { createBrowserHistory } from "history";
const moment1 = require("moment-timezone");

const OrderDetailForm = (props) => {
  const { values, handleChange, onChange, setFieldValue } = props;
  const [homeAccessNotesShow, setHomeAccessNotesShow] = useState(false);
  const [cleanerEmailRequired, setCleanerEmailRequired] = useState(false);
  const [assignError, setAssignGraphqlError] = useState("");
  const [editError, setEditError] = useState("");
  const history = createBrowserHistory();
  const [isFogging, setIsFogging] = useState(
    props.order && props.order.serviceType === "FOGGING" ? true : false
  );
  console.log(props.order.serviceDateTime);

  useEffect(() => {
    if (onChange && props.order.status !== "CANCELLED") {
      onChange(values);
      // props.calculateUpdatedOrderAmount(
      //   (values, props.order.serviceDateTime, props.summary.outstanding)
      // );
    }
  }, [values]);
  const handleAssignCleaner = () => {
    let cleaner;
    if (props.order.cleaner) {
      cleaner = props.order.cleaner;
    }
    postWithAuth(ASSIGN_ORDER_ADMIN, {
      id: props.order.id,
      cleanerEmail: values.cleanerEmail,
    }).then((res) => {
      if (res.data.data.assignOrderAdmin) {
        sendOrderMsgToAssignedStaff(
          res.data.data.assignOrderAdmin.cleaner.id,
          props.order.id,
          "CONFIRMED"
        ).then((res) => {
          if (cleaner) {
            sendOrderMsgToAssignedStaff(
              cleaner.id,
              props.order.id,
              "PENDING"
            ).then((res) => {
              history.go(0);
            });
          } else {
            history.go(0);
          }
        });
      } else {
        if (res.data.errors[0].message) {
          setAssignGraphqlError(res.data.errors[0].message);
        }
      }
    });
  };
  return (
    <Mutation
      mutation={UPDATE_ORDER_ADMIN}
      onCompleted={(data) => {
        alert("Update order successfully");
      }}
      onError={(error) => {
        setEditError(
          error.graphQLErrors ? error.graphQLErrors[0].message : error.message
        );
      }}
    >
      {(updateOrderAdmin) => (
        <div className="p-4">
          <form
              onSubmit={async (e) => {
                e.preventDefault();
                console.log(values.status);
                if (values.assign === true) {
                  handleAssignCleaner();
                } else {
                  values.outstanding = props.summary.outstanding;
                  values.subTotal = props.summary.subTotal;
                  values.tax = props.summary.tax;
                  values.total = props.summary.total;
                  updateOrderAdmin({
                    variables: values,
                  });
                }
              }}
          >
            {values.status !== "CANCELLED" && (
                <h4 className="mb-3">Edit Order</h4>
            )}
            {values.status === "CANCELLED" && (
                <h4 className="mb-3" style={{color: "red"}}>
                  This Order has been cancelled
                </h4>
            )}
            <p className="lead idText">
              ID: {`${values.id.slice(-8)} `}{" "}
              <span className="statusText">STATUS: {`${values.status}`}</span>
            </p>
            <div className="row">
              {props.order.status === "CANCELLED" && (
                  <div className="col-md-6 mb-3">
                    <label htmlFor="refundStatus">Refund Status</label>
                    <div className="input-group">
                      <select
                          className="custom-select d-block w-100"
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                      >
                        <option value={props.order.status}>NOT REFUNDED</option>
                        <option value="REFUNDED">REFUNDED</option>
                      </select>
                    </div>
                  </div>
              )}

              {props.order.status === "COMPLETED" && (
                  <div className="col-md-6 mb-3">
                    <label htmlFor="payrollStatus">Payroll Status</label>
                    <div className="input-group">
                      <select
                          className="custom-select d-block w-100"
                          name="payrollStatus"
                          value={values.payrollStatus}
                          onChange={handleChange}
                      >
                        <option value="NOT_PAID">NOT PAID</option>
                        <option value="PAID">PAID</option>
                      </select>
                    </div>
                  </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="service">
                Please select the type of cleaning service you are requesting
              </label>
              <div className="input-group">
                <select
                    className="custom-select d-block w-100"
                    name="serviceType"
                    required
                    value={values.serviceType}
                    onChange={handleChange}
                    disabled
                >
                  <option value="AIRBNB">Airbnb Cleaning</option>
                  <option value="MOVING">Moving Out Cleaning</option>
                  <option value="FOGGING">Fogging Service</option>
                </select>
                <p>Salary: ${props.order.cleanHours * 25}</p>
              </div>
            </div>
            <hr className="mb-4 mt-4"/>
            <div className="mb-3">
              <p> Tell us about your property</p>
              <label htmlFor="room">Type</label>
              <div className="input-group">
                <select
                    className="custom-select d-block w-100"
                    required
                    name="roomType"
                    value={values.roomType}
                    disabled
                    onChange={(e) => {
                      handleChange(e);
                      if (values.roomType === "HOUSE/TOWNHOUSE") {
                        setFieldValue("bedroom", "2");
                        setFieldValue("bathroom", "1-3");
                      }
                      if (values.roomType === "APARTMENT/BASEMENT") {
                        setFieldValue("bedroom", "3");
                        setFieldValue("bathroom", "1-2");
                        setFieldValue("hours", "3.5");
                      }
                    }}
                >
                  <option value="HOUSE/TOWNHOUSE">House / Town House</option>
                  <option value="APARTMENT/BASEMENT">
                    Apartment / Basement
                  </option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mb-3">
                <label htmlFor="bedroom">Bedroom</label>
                <p>{values.bedroom}</p>
                {/* {values.roomType === 'APARTMENT/BASEMENT' && (
                  <select
                    className='custom-select d-block w-100'
                    name='bedroom'
                    required
                    value={values.bedroom}
                    disabled
                    onChange={(e) => {
                      handleChange(e);
                      if (values.roomType === 'HOUSE/TOWNHOUSE') {
                        setFieldValue('bedroom', '2');
                        setFieldValue('bathroom', '1-3');
                      }
                    }}
                  >
                    <option value='1'>1 Bedroom</option>
                    <option value='2'>2 Bedroom</option>
                    <option value='3'>3 Bedroom</option>
                    <option value='4'>4 Bedroom</option>
                  </select>
                )}

                {values.roomType === 'HOUSE/TOWNHOUSE' && (
                  <select
                    className='custom-select d-block w-100'
                    name='bedroom'
                    required
                    disabled
                    value={values.bedroom}
                    onChange={handleChange}
                  >
                    <option value='1'>1 Bedroom</option>
                    <option value='2'>2 Bedroom</option>
                    <option value='3'>3 Bedroom</option>
                    <option value='4'>4 Bedroom</option>
                    <option value='5'>5 Bedroom</option>
                    <option value='6'>6 Bedroom</option>
                    <option value='7'>7 Bedroom</option>
                    <option value='8'>8 Bedroom</option>
                  </select>
                )} */}
                <div className="invalid-feedback">
                  Please select a valid country.
                </div>
              </div>
              {/* {isFogging && (
                <div className='col-md-4'>
                  <label htmlFor='ft'>Ft</label>
                  <p>{`${props.order.ft}-${parseInt(props.order.ft) + 499}`}</p>
                </div>
              )} */}
              <div className="col-md-4 mb-3">
                <label htmlFor="bathroom">Bathroom</label>
                <p>{values.bathroom}</p>
              </div>
              {/* {!isFogging && (
                <div className='col-md-4 mb-3'>
                  <label htmlFor='bathroom'>Bathroom</label>
                  {values.roomType === 'APARTMENT/BASEMENT' &&
                    (values.bedroom === '1' ||
                      values.bedroom === '2' ||
                      values.bedroom === '3') && (
                      <select
                        className='custom-select d-block w-100'
                        name='bathroom'
                        required
                        disabled
                        value={values.bedroom}
                        onChange={handleChange}
                      >
                        <option value='1-3'>1-3 Bathroom</option>
                      </select>
                    )}

                  {values.roomType === 'APARTMENT/BASEMENT' &&
                    values.bedroom === '4' && (
                      <select
                        className='custom-select d-block w-100'
                        name='bathroom'
                        required
                        disabled
                        value={values.bathroom}
                        onChange={handleChange}
                      >
                        <option value='1-3'>1-3 Bathroom</option>
                        <option value='4'>4 Bathroom</option>
                      </select>
                    )}

                  {values.roomType === 'HOUSE/TOWNHOUSE' &&
                    (values.bedroom === '1' || values.bedroom === '2') && (
                      <select
                        className='custom-select d-block w-100'
                        name='bathroom'
                        required
                        disabled
                        value={values.bathroom}
                        onChange={handleChange}
                      >
                        <option value='1-3'>1-3 Bathroom</option>
                      </select>
                    )}

                  {values.roomType === 'HOUSE/TOWNHOUSE' &&
                    values.bedroom === '3' && (
                      <select
                        className='custom-select d-block w-100'
                        name='bathroom'
                        required
                        disabled
                        value={values.bathroom}
                        onChange={handleChange}
                      >
                        <option value='1-2'>1-2 Bathroom</option>
                        <option value='3'>3 Bathroom</option>
                      </select>
                    )}
                  {values.roomType === 'HOUSE/TOWNHOUSE' &&
                    (values.bedroom === '4' || values.bedroom === '5') && (
                      <select
                        className='custom-select d-block w-100'
                        name='bathroom'
                        required
                        disabled
                        value={values.bathroom}
                        onChange={handleChange}
                      >
                        <option value='1-3'>1-3 Bathroom</option>
                        <option value='4'>4 Bathroom</option>
                        <option value='5'>5 Bathroom</option>
                      </select>
                    )}

                  {values.roomType === 'HOUSE/TOWNHOUSE' &&
                    (values.bedroom === '6' || values.bedroom === '7') && (
                      <select
                        className='custom-select d-block w-100'
                        name='bathroom'
                        required
                        disabled
                        value={values.bathroom}
                        onChange={handleChange}
                      >
                        <option value='1-3'>1-3 Bathroom</option>
                        <option value='4'>4 Bathroom</option>
                        <option value='5'>5 Bathroom</option>
                        <option value='6'>6 Bathroom</option>
                      </select>
                    )}

                  {values.roomType === 'HOUSE/TOWNHOUSE' &&
                    values.bedroom === '8' && (
                      <select
                        className='custom-select d-block w-100'
                        name='bathroom'
                        required
                        disabled
                        value={values.bathroom}
                        onChange={handleChange}
                      >
                        <option value='1-3'>1-3 Bathroom</option>
                        <option value='4'>4 Bathroom</option>
                        <option value='5'>5 Bathroom</option>
                        <option value='6'>6 Bathroom</option>
                        <option value='7'>7 Bathroom</option>
                      </select>
                    )}
                </div>
              )} */}

              <div className="col-md-4 mb-3">
                <label htmlFor="bathroom">Cleaning Hours</label>
                <p>{values.cleanHours}</p>
              </div>
            </div>

            <hr className="mb-4 mt-4"/>
            <div className="row">
              <div className="col-6">
                <strong>Special Request</strong>
                <input
                    type="text"
                    className="form-control"
                    disabled
                    required
                    name="specialRequest"
                    value={values.specialRequest ? values.specialRequest : "N/A"}
                />
              </div>
              <div className="col-6">
                <label htmlFor="extraServices">Additional Services</label>
                <FieldArray
                    name="extraServices"
                    render={(arrayHelpers) => (
                        <div>
                          {ExtraServicesType.map((extraService) => (
                              <div key={extraService.id}>
                                <label>
                                  <input
                                      name="extraServices"
                                      type="checkbox"
                                      disabled
                                      value={extraService.id}
                                      checked={values.extraServices.includes(
                                          extraService.id
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          arrayHelpers.push(extraService.id);
                                        else {
                                          const idx = values.extraServices.indexOf(
                                              extraService.id
                                          );
                                          arrayHelpers.remove(idx);
                                        }
                                      }}
                                  />
                                  {extraService.name}
                                </label>
                              </div>
                          ))}
                        </div>
                    )}
                />
              </div>
            </div>

            <hr className="mb-4 mt-4"/>
            <div className="mb-3">
              <p>Property Address</p>
              <div className="row">
                <div className="col-md-9 mb-3">
                  <input
                      type="text"
                      className="form-control"
                      required
                      placeholder="123 Marine Drive"
                      name="street"
                      value={values.street}
                      onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Apt/Suite"
                      name="apartment"
                      value={values.apartment}
                      onChange={handleChange}
                  />
                  <div className={values.apartment ? "invalid-feedback" : ""}>
                    {values.apartment}
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <select
                      className="custom-select d-block w-100"
                      required
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                  >
                    {CityType.map((city) => {
                      return (
                          <option key={city.id} value={city.name}>
                            {city.name}
                          </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-3 mb-3">
                  <input
                      type="text"
                      className="form-control"
                      placeholder="BC"
                      required
                      disabled
                      name="province"
                      value={values.province}
                      onChange={handleChange}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <input
                      type="text"
                      className="form-control"
                      placeholder="V3A4H7"
                      pattern="[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d"
                      name="postCode"
                      value={values.postCode}
                      onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <hr className="mb-4"/>
            <div className="mb-5">
              <label htmlFor="serviceTime">Service Time</label>

              <div className="mb-5">
                <label htmlFor="serviceTime">Check Out Time</label>

                <div className="input-group">
                  <DatePicker
                      name="serviceTime"
                      value={values.serviceDateTime}
                      onChange={(_, val) => {
                        setFieldValue("serviceDateTime", val);
                      }}
                  />
                </div>
                <label htmlFor="serviceTime"> Check In Time</label>
                <div className="input-group">{values.checkInTime}</div>
              </div>
            </div>
            <hr className="mb-4"/>
            <div className="mb-4">
              <label htmlFor="homeAccess">How to get in</label>
              <div className="input-group">
                <select
                    className="custom-select d-block w-100"
                    required
                    name="homeAccess"
                    value={values.homeAccess}
                    onChange={(e) => {
                      e.target.value === "See notes below"
                          ? setHomeAccessNotesShow(true)
                          : setHomeAccessNotesShow(false);
                      handleChange(e);
                    }}
                >
                  {HomeAccessType.map((homeAccessType) => {
                    return (
                        <option
                            key={homeAccessType.id}
                            value={homeAccessType.name}
                        >
                          {homeAccessType.name}
                        </option>
                    );
                  })}
                </select>
              </div>
              {values.homeAccess === "I will set up a Keycafe" && (
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="keycafe_address">Keycafe Address</label>
                      <input
                          className="form-control"
                          name="keycafe_address"
                          value={values.keycafe_address}
                          onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="keycafe_code">Access Code</label>
                      <input
                          className="form-control"
                          name="keycafe_code"
                          value={values.keycafe_code}
                          onChange={handleChange}
                      />
                    </div>
                  </div>
              )}
              {values.homeAccess === "See notes below" && (
                  <div className="mt-4 ">
                    <div className="input-group">
                    <textarea
                        className="form-control"
                        rows="3"
                        placeholder="Please pick up key at my office at 388 Kingsway"
                        name="homeAccessNotes"
                        value={values.homeAccessNotes}
                        onChange={handleChange}
                    ></textarea>
                    </div>
                  </div>
              )}
            </div>
            <hr className="mb-4"/>
            <div className="mb-3">
              <label htmlFor="customerInfo">Customer Information</label>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="lastName">First Name</label>
                  <input
                      type="text"
                      className="form-control"
                      required
                      placeholder="First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                      type="text"
                      className="form-control"
                      required
                      placeholder="Last Name"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="lastName">Email</label>
                  <input
                      type="text"
                      className="form-control"
                      required
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="lastName">Phone Number</label>
                  <input
                      type="text"
                      className="form-control"
                      required
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="customerFeedback">Feedback for customer</label>
                  <textarea
                      className="form-control"
                      rows="3"
                      placeholder="Feedback for customer"
                      name="customerFeedback"
                      value={values.customerFeedback}
                      onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              {props.order.status === "COMPLETED" &&
                  props.order.suppliesNone === false && (
                      <div>
                        <hr className="mb-4"/>
                        <p className="font-weight-bold">Supplies need to restock</p>
                      </div>
                  )}

              {values.suppliesHealth.length > 0 && (
                  <div className="row">
                    <p className="col-md-12">
                      <i className="fas fa-arrow-right"></i> Health
                    </p>
                    {values.suppliesHealth.map((sh) => {
                      return (
                          <div className="col-md-4" key={sh}>
                            <p>{sh}</p>
                          </div>
                      );
                    })}
                  </div>
              )}

              {values.suppliesLaundry.length > 0 && (
                  <div className="row ">
                    <p className="col-md-12">
                      <i className="fas fa-arrow-right"></i> Laundry
                    </p>
                    {values.suppliesLaundry.map((sl) => {
                      return (
                          <div className="col-md-4" key={sl}>
                            <p>{sl}</p>
                          </div>
                      );
                    })}
                  </div>
              )}

              {values.suppliesCleaning.length > 0 && (
                  <div className="row">
                    <p className="col-md-12">
                      <i className="fas fa-arrow-right"></i> Cleaning
                    </p>
                    {values.suppliesCleaning.map((sc) => {
                      return (
                          <div className="col-md-4" key={sc}>
                            <p>{sc}</p>
                          </div>
                      );
                    })}
                  </div>
              )}

              {(values.faceCloth ||
                  values.pillowCase ||
                  values.queenSizeBedsheet ||
                  values.kingSizeBedsheet ||
                  values.queenSizeDuvetCover ||
                  values.kingSizeDuvetCover ||
                  values.bathTowels) && (
                  <p>
                    <i className="fas fa-arrow-right"></i> Bedding
                  </p>
              )}
              <div className="row">
                {values.pillowCase && (
                    <div className="col-md-4">
                      <p>Pillow Case : {values.pillowCase}</p>
                    </div>
                )}

                {values.queenSizeBedsheet && (
                    <div className="col-md-4">
                      <p>Queen Size Bedsheet: {values.queenSizeBedsheet}</p>
                    </div>
                )}

                {values.kingSizeBedsheet && (
                    <div className="col-md-4">
                      <p>Queen Size Bedsheet : {values.kingSizeBedsheet}</p>
                    </div>
                )}

                {values.queenSizeDuvetCover && (
                    <div className="col-md-4">
                      <p>Queen Size Duvet Cover : {values.queenSizeDuvetCover}</p>
                    </div>
                )}

                {values.kingSizeDuvetCover && (
                    <div className="col-md-4">
                      <p>King Size Duvet Cover : {values.kingSizeDuvetCover}</p>
                    </div>
                )}

                {values.bathTowels && (
                    <div className="col-md-4">
                      <p>Bath Towels: {values.bathTowels}</p>
                    </div>
                )}

                {values.faceCloth && (
                    <div className="col-md-4">
                      <p>Face Cloth : {values.faceCloth}</p>
                    </div>
                )}
              </div>
            </div>

            <hr className="mb-4"/>
            {values.status !== "CANCELLED" && (
                <button
                    type="submit"
                    className="btn btn-block submit-btn"
                    style={{
                      backgroundColor: "#3ecad2",
                      color: "white",
                    }}
                >
                  Confirm Change
                </button>
            )}
            {editError && (
                <p className="lead mt-4" style={{color: "red"}}>
                  {editError}
                </p>
            )}
            {props.order.cleaner && (
                <div>
                  <hr className="mb-4"/>
                  <div className="mb-3">
                    <label htmlFor="cleaner">Assigned Staff Info</label>
                    <Link
                        to={`/dashboard/cleaner/${props.order.cleaner.id}`}
                        className="btn"
                        href=""
                    >
                      Click to see the cleaner detail
                    </Link>
                    <p>
                      Name:
                      {`${props.order.cleaner.firstName} ${props.order.cleaner.lastName}`}
                    </p>
                    <p>
                      PhoneNumber:
                      {`${props.order.cleaner.cleanerInfo.phoneNumber}`}
                    </p>
                  </div>
                </div>
            )}
            <div className="div mt-3 mb-3">
              <div className="row">
                <div className="col-md-10">
                  <input
                      type="email"
                      className="form-control"
                      name="cleanerEmail"
                      placeholder="CLEANER EMAIL"
                      required={cleanerEmailRequired}
                      value={values.cleanerEmail}
                      onChange={handleChange}
                  />
                </div>
                <div className="col-md-2">
                  <button
                      className="btn submit-btn btn-block"
                      style={{
                        backgroundColor: "#3ecad2",
                        color: "white",
                      }}
                      onClick={async () => {
                        await setCleanerEmailRequired(true);
                        await setFieldValue("assign", true);
                      }}
                  >
                    Assign
                  </button>
                </div>
                {assignError && (
                    <p className="lead mt-4" style={{color: "red"}}>
                      {assignError}
                    </p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label htmlFor="cleanerfeedback">Feedback for cleaner</label>
                <textarea
                    className="form-control"
                    rows="3"
                    placeholder="Feedback for cleaner"
                    name="cleanerFeedback"
                    value={values.cleanerFeedback}
                    onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </form>
        </div>
      )}
    </Mutation>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    return {
      id: props.order.id,
      hours: props.order.hours,
      status: props.order.status,
      phoneNumber: props.order.phoneNumber,
      roomType: props.order.roomType,
      serviceType: props.order.serviceType,
      bedroom: props.order.bedroom,
      bathroom: props.order.bathroom,
      extraServices: props.order.extraServices,
      subTotal: props.order.subTotal,
      tax: props.order.tax,
      total: props.order.total,
      street: props.order.street,
      apartment: props.order.apartment,
      city: props.order.city,
      province: props.order.province,
      postCode: props.order.postCode,
      customerFeedback: props.order.customerFeedback,
      cleanerFeedback: props.order.cleanerFeedback,
      serviceDateTime: moment1
        .unix(props.order.serviceDateTime / 1000)
        .tz("UTC")
        .format("MM/DD/YYYY HH:MM"),
      homeAccess: props.order.homeAccess,
      homeAccessNotes: props.order.homeAccessNotes,
      promoCode: props.order.promoCode,
      firstName: props.order.customer.firstName,
      lastName: props.order.customer.lastName,
      email: props.order.customer.email,
      outstanding: props.order.outstanding,
      cleanerEmail: "",
      assign: false,
      payrollStatus: props.order.payrollStatus ? props.order.payrollStatus : "",
      suppliesHealth: props.order.suppliesHealth,
      suppliesLaundry: props.order.suppliesLaundry,
      suppliesCleaning: props.order.suppliesCleaning,
      pillowCase: props.order.pillowCase,
      queenSizeBedsheet: props.order.queenSizeBedsheet,
      kingSizeBedsheet: props.order.kingSizeBedsheet,
      queenSizeDuvetCover: props.order.queenSizeDuvetCover,
      kingSizeDuvetCover: props.order.kingSizeDuvetCover,
      bathTowels: props.order.bathTowels,
      faceCloth: props.order.faceCloth,
      keycafe_address: props.order.keycafe_address,
      keycafe_code: props.order.keycafe_code,
      specialRequest: props.order.specialRequest,
      cleanHours: props.order.cleanHours,
      ft: props.order.ft,
      checkInTime: props.order.checkInTime,
    };
  },
})(OrderDetailForm);
