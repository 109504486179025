import Layout from "../components/Layout";
import SigninForm from "../components/SigninForm";
import React from "react";

const Index = () => {
  return (
    <Layout>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-5 m-auto">
            <div className="card card-body">
              <SigninForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Index;
