import axios from 'axios';
// import { IN_PROD, LOCAL_API, PRODUCTION_API } from "../lib/config";
import { print } from 'graphql';
import { AREA_CLEANERS, CLEANERINFO_USERID } from '../queries/user';
export const postWithAuth = async (query, variables) => {
  let URL = '/graphql';
  try {
    let res = axios.post(URL, { query: print(query), variables: variables });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const sendOrderMsgGroup = (orderId, city, serviceType, type) => {
  console.log('called sendOrderMsgGroup');
  postWithAuth(AREA_CLEANERS, {
    area: city,
    serviceType: serviceType,
  }).then((res) => {
    let promises = [];
    res.data.data.area_cleaners.forEach((cleaner) => {
      promises.push(
        sendText(cleaner.phoneNumber, cleaner.cleaner.firstName, orderId, type)
      );
    });
    return Promise.all(promises);
  });
};

export const sendOrderMsgToAssignedStaff = (userId, orderId, type) => {
  console.log('called sendOrderMsgToAssignedStaff');
  return postWithAuth(CLEANERINFO_USERID, {
    userId: userId,
  }).then((res) => {
    return sendText(
      res.data.data.cleanerInfo_userId.phoneNumber,
      res.data.data.cleanerInfo_userId.cleaner.firstName,
      orderId,
      type
    );
  });
};

export const sendText = (phoneNumber, firstName, orderId, type) => {
  return fetch('/api/messages', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      phoneNumber: phoneNumber,
      firstName: firstName,
      orderId: orderId,
      type: type,
    }),
  }).then((res) => {
    console.log(res);
    return res.json();
  });
};

export const withinLimitDays = (day, date) => {
  const dateMilliseconds = new Date(date).getTime();
  return dateMilliseconds - 86400000 * day < new Date().getTime();
};
