import React from 'react';
import { Mutation } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import { SETTING, UPDATE_SETTING } from '../queries/user';
import { withFormik, FieldArray, Formik, Field } from 'formik';
const MessageSwtichButton = () => {
  const settingId = '6163405311c0324fb3eef301';
  let { data, loading, error } = useQuery(SETTING, {
    variables: { id: settingId },
  });
  if (loading) {
    return <p>loading</p>;
  }
  if (error) {
    return <p>error</p>;
  }
  return (
    <div>
      <h1>Message Switch Button</h1>
      <Mutation
        mutation={UPDATE_SETTING}
        onCompleted={(data) => {
          console.log(data);
          window.location.reload();
        }}
        onError={(error) => {
          console.log(error);
        }}
      >
        {(updateSetting) => (
          <Formik
            enableReinitialize
            initialValues={{
              sms: data.setting.sms,
              id: settingId,
            }}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(false);
              updateSetting({ variables: values });
            }}
            render={({ values, handleChange, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <select
                  className='custom-select d-block w-100'
                  name='sms'
                  value={values.sms}
                  onChange={handleChange}
                >
                  {['true', 'false'].map((status) => {
                    return (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    );
                  })}
                </select>
                <div>
                  <button
                    className='btn'
                    style={{
                      backgroundColor: '#3ecad2',
                      color: 'white',
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          />
        )}
      </Mutation>
    </div>
  );
};

export default MessageSwtichButton;
