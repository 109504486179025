import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from "react-router-dom";
const ConfirmModal = (props) => {
  const [modal, setModal] = useState(false);

  const toggle = (value) => {
    setModal(!modal);
    if (value === 'confirm') {
      props.handleSubmit();
    }
  };
  const button = (type) => {
      if (type == "button"){
          return (
              <div
                  className='formContainer mt-3'
                  style={{border: '2px solid #8492a6', borderRadius: '25px'}}
              >
                  <div className='p-1 d-flex justify-content-between align-items-center'>
                      <button
                          className='btn mx-auto bdbtn'
                          style={{
                              color: '#3ecad2',
                              outline: 'none',
                              boxShadow: 'none',
                          }}
                          onClick={toggle}
                      >{props.title}
                      </button>
                  </div>
              </div>
          )
      }
      if(type=='delete-icon')
      {
          return (
                  <button
                      className='btn mx-auto bdbtn'
                      style={{
                          color: 'rgb(69 130 236)',
                          outline: 'none',
                          boxShadow: 'none',
                          cursor:'pointer'
                      }}
                      onClick={toggle}
                  >< i className = {props.icon} > < /i>
                  </button>
              )

      }
      if(type=='delete-icon-link')
      {
          return (
              <Link to={props.link}>
                  <button
                      className='btn mx-auto bdbtn'
                      style={{
                          color: 'rgb(69 130 236)',
                          outline: 'none',
                          boxShadow: 'none',
                          cursor:'pointer'
                      }}
                  >< i className = {props.icon} > < /i>
                  </button>
              </Link>
              )

      }
  }
    return (
        <div>
            {button(props.type)}
            <Modal isOpen={modal} toggle={toggle}>
                <ModalBody>
                    <p>{props.des}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={toggle}>
                    No
                    </Button>
                    <Button
                        color='primary'
                        value='confirm'
                        onClick={() => toggle('confirm')}
                    >
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ConfirmModal;
