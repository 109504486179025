import React from 'react';
const OrderSummaryCard = (props) => {
  const {
    street,
    city,
    subTotal,
    tax,
    total,
    outstanding,
    discount,
    status,
    discountValue,
  } = props;

  const RefundingCard = () => (
    <div>
      <div className='d-flex justify-content-between'>
        <h6 className='lead'>Cancellation Fee</h6>
        <h6 className='lead'>{`-${outstanding}`}</h6>
      </div>
      <div className='d-flex justify-content-between'>
        <h6 className='lead' style={{ color: 'red' }}>
          Refunding
        </h6>
        <h6 className='lead' style={{ color: 'red' }}>{`${
          total - outstanding
        }`}</h6>
      </div>
    </div>
  );

  const NewBalanceCard = () => (
    <div>
      <div className='d-flex justify-content-between'>
        <h6 className='lead' style={{ color: 'red' }}>
          Cancellation Fee
        </h6>
        <h6 className='lead' style={{ color: 'red' }}>{`-${outstanding}`}</h6>
      </div>
      <div className='d-flex justify-content-between'>
        <h6 className='lead' style={{ color: 'red' }}>
          Refunded
        </h6>
        <h6 className='lead' style={{ color: 'red' }}>{`${
          total - outstanding
        }`}</h6>
      </div>
    </div>
  );

  return (
    <div
      className='formContainer'
      style={{ border: '2px solid #8492a6', borderRadius: '25px' }}
    >
      <div className='p-4'>
        <h4 className='d-flex justify-content-between align-items-center mb-3'>
          <span className='mx-auto' style={{ color: '#3ecad2' }}>
            Booking Summary
          </span>
        </h4>
        <hr className='mb-4 ' />
        <div>
          <h6 className='my-0 lead'>PROPERTY</h6>
          <h6 className='text-muted lead mt-2'>{`${street}, ${city}`}</h6>
        </div>
        <hr className='mb-4 ' />
        <div className='d-flex justify-content-between lh-condensed'>
          <div>
            <h6 className='text-muted lead'>SUBTOTAL</h6>
          </div>
          <h6 className='text-muted lead'>{`${subTotal}`}</h6>
        </div>
        <div
          className='d-flex justify-content-between lh-condensed'
          style={{ borderTop: 'none' }}
        >
          <div>
            <h6 className='text-muted lead'>SALES TAX</h6>
          </div>
          <h6 className='text-muted lead'>{`${tax}`}</h6>
        </div>

        <hr className='mb-4 ' />

        <div className='d-flex justify-content-between'>
          <h6 className='lead'>TOTAL </h6>
          <h6 className='text-muted lead'>{`${total}`}</h6>
        </div>
        {discount != null && discount > 0 && (
          <strong className='lead mx-auto' style={{ color: '#067555' }}>
            {`${discount}%`} Discount Applied
          </strong>
        )}
        {discount != null && discount == -1 && (
          <strong className='lead mx-auto' style={{ color: '#067555' }}>
            {`$${discountValue}`} Discount Applied
          </strong>
        )}
        {outstanding != null && outstanding !== 0 && status === 'CANCELLED' && (
          <RefundingCard />
        )}
        {outstanding != null && outstanding !== 0 && status !== 'CANCELLED' && (
          <NewBalanceCard />
        )}
      </div>
    </div>
  );
};

export default OrderSummaryCard;
