import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
// import {  useMutation } from '@apollo/client';
import { ORDERS } from "../queries/order";
import Layout from "../components/Layout";
import ConfirmModal from "../components/confirmModal";
import * as queryString from "query-string";
import * as moment from "moment";
import {DELETE_ORDER_BY_ADMIN} from "../queries/order";
import OrderChangeModal from "../components/OrderChangeModal";
const moment1 = require("moment-timezone");

const Orders = (props) => {
  const queryParams = queryString.parse(props.location.search);
  console.log("queryParams", queryParams);
  if (!queryParams.page) {
    queryParams.page = 1;
  }

  if (queryParams.page) queryParams.page = parseInt(queryParams.page);
  if (queryParams.service) queryParams.service = parseInt(queryParams.service);
  if (queryParams.create) queryParams.create = parseInt(queryParams.create);
  const { data, loading, error } = useQuery(ORDERS, {
    variables: queryParams,
    fetchPolicy: "no-cache",
  });
  const [keyword, setKeyword] = useState(queryParams.keyword || "");
  const [searchId, setSearchId] = useState(queryParams.id || "");
  const [deleteLOrder, { deleteData, deleteOrderloading, deleteOrderError, reset }] = useMutation(DELETE_ORDER_BY_ADMIN);

  if (error) console.log(error);
  data && console.log(data);

  let serviceTimeSortIcon = "fa-sort-down";
  let createTimeSortIcon = "fa-sort-down";

  if (queryParams.service == 1) {
    serviceTimeSortIcon = "fa-sort-up";
  } else {
    queryParams.service = -1;
  }

  if (queryParams.create == 1) {
    createTimeSortIcon = "fa-sort-up";
  } else {
    queryParams.create = -1;
  }

  function addParam(key, value) {
    let params = queryString.parse(props.location.search);
    params[key] = value;
    console.log(params);
    if (key === "id" || key === "keyword") {
      delete params.page;
      if (key === "id") {
        delete params.keyword;
      } else {
        delete params.id;
      }
    }
    props.history.push({ search: "?" + queryString.stringify(params) });
  }

  function handleKeywordSearch(e) {
    e.preventDefault();

    addParam("keyword", keyword);
  }
  function onKeywordSearchInput(e) {
    setKeyword(e.target.value);
  }
  function handleIdSearch(e) {
    e.preventDefault();
    addParam("id", searchId);
  }
  function onIdSearchInput(e) {
    setSearchId(e.target.value);
  }

  return (
    <Layout>
      <h1> Orders</h1>
      {loading && <p>LOADING ORDERS</p>}
      {error && <p>ERROR</p>}
      {data && (
        <div className="container-fluid">
          <div className="row">
            <form className="col form-inline" onSubmit={handleIdSearch}>
              <div className="form-group mx-sm-3 mb-2">
                <input
                  id="search"
                  onChange={onIdSearchInput}
                  name="search"
                  value={searchId}
                  placeholder="Order/Customer ID"
                  className="form-control"
                  type="text"
                />
              </div>
              <button className="btn btn-primary mb-2" type="submit">
                Order ID Search
              </button>
            </form>
            <form className="col form-inline" onSubmit={handleKeywordSearch}>
              <div className="form-group mx-sm-3 mb-2">
                <input
                  id="search"
                  onChange={onKeywordSearchInput}
                  name="search"
                  value={keyword}
                  className="form-control input-lg"
                  type="text"
                  placeholder="Phone#/Status/Time"
                />
              </div>
              <button className="btn btn-primary mb-2" type="submit">
                Keyword Search
              </button>
            </form>
          </div>
          {data.orders.length ? (
            <div>
              <table className="table table-hover text-center">
                <thead>
                  <tr>
                    <th scope="col">Booking No.</th>
                    <th scope="col">
                      <Link
                        to={
                          "/dashboard/orders" +
                          "?" +
                          queryString.stringify({
                            page: queryParams.page,
                            service: -parseInt(queryParams.service),
                            create: queryParams.create,
                            sort: "service",
                          })
                        }
                      >
                        Checkout Time{" "}
                        <i className={"fas " + serviceTimeSortIcon}></i>
                      </Link>
                    </th>
                    <th scope="col">
                      {" "}
                      <Link
                        to={
                          "/dashboard/orders" +
                          "?" +
                          queryString.stringify({
                            page: queryParams.page,
                            service: queryParams.service,
                            create: -parseInt(queryParams.create),
                            sort: "create",
                          })
                        }
                      >
                        Create At{" "}
                        <i className={"fas " + createTimeSortIcon}></i>
                      </Link>
                    </th>
                    <th scope="col">Name</th>
                    <th scope="col">Phone#</th>
                    <th scope="col">Extra Service</th>
                    <th scope="col">Cleaner</th>
                    <th scope="col">Payment</th>
                    <th scope="col">Status</th>
                    <th scope="col">Payroll</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {data.orders &&
                    data.orders.map((order) => (
                      <tr key={order.id}>
                        <th scope="row">{order.id.slice(-8)}</th>
                        <td>
                          {isNaN(order.serviceDateTime)
                            ? order.serviceDateTime
                            : moment1
                                .unix(order.serviceDateTime / 1000)
                                .tz("UTC")
                                .format("h:mm A, MM/DD/YYYY")}
                        </td>
                        <td>
                          {moment
                            .unix(order.createdAt / 1000)
                            .format("h:mm A, MM/DD/YYYY")}
                        </td>
                        <td>
                          {order?.customer?.firstName +
                            " " +
                            order?.customer?.lastName}
                        </td>
                        <td>{order.phoneNumber}</td>
                        <td>
                          <ul>
                            {order.extraServices.length > 0 &&
                            order.extraServices[0] != ""
                              ? order.extraServices.map((extraService) => {
                                  return (
                                    <li key={extraService}>{extraService}</li>
                                  );
                                })
                              : "n/a"}
                          </ul>
                        </td>

                        <td>
                          {order.cleaner
                            ? order.cleaner.firstName +
                              " " +
                              order.cleaner.lastName
                            : ""}
                        </td>
                        <td>{order.total}</td>
                        <td>{order.status}</td>
                        <td>{order.payrollStatus}</td>
                        <td>
                          {/*<Link to={`/dashboard/order/${order.id}`}>*/}
                          {/*  <i className="fa fa-edit fa-2x"></i>*/}
                          {/*</Link>*/}
                          <ConfirmModal
                              title='Delete order'
                              type='delete-icon-link'
                              icon='fa fa-edit fa-2x'
                              des={'delete order'}
                              link={'/dashboard/order/'+order.id}
                              id={order.id}
                          />
                        </td>
                        <td>
                          <ConfirmModal
                              title="Delete order"
                              type="delete-icon"
                              icon="fa fa-trash fa-2x"
                              des='Are you certain you wish to proceed with deletion?'
                              id={order.id}
                              handleSubmit={()=>{deleteLOrder({ variables: { "id":order.id}}).then(()=>{
                                // setUpdate("user deleted")
                                window.location.reload(); // todo: remove with component refresh
                              });
                              }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No more orders</p>
          )}
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className="page-item">
                <button
                  hidden={queryParams.page <= 1}
                  onClick={() => {
                    if (!queryParams.page) {
                      queryParams.page = 1;
                    }
                    const nextPage = +queryParams.page - 1;
                    addParam("page", nextPage);
                  }}
                  className="page-link"
                >
                  Previous
                </button>
              </li>
              <li className="page-item">
                <button
                  hidden={data.orders.length !== 10}
                  onClick={() => {
                    if (!queryParams.page) {
                      queryParams.page = 1;
                    }
                    const nextPage = +queryParams.page + 1;
                    addParam("page", nextPage);
                  }}
                  className="page-link"
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </Layout>
  );
};
export default Orders;
