import React, { useState } from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import { CLEANERS } from "../queries/user";
import Layout from "../components/Layout";
import * as queryString from "query-string";
import * as moment from "moment";
import ConfirmModal from "../components/confirmModal";
import {DELETE_USER_BY_ADMIN} from "../queries/user";

const Cleaners = (props) => {
  const queryParams = queryString.parse(props.location.search);
  if (!queryParams.page) {
    queryParams.page = 1;
  }
  if (queryParams.page) queryParams.page = parseInt(queryParams.page);
  const { data, loading, error } = useQuery(CLEANERS, {
    variables: queryParams,
  });
  const [updateapp, setUpdate] = useState("");
  const [keyword, setKeyword] = useState(queryParams.keyword || "");
  const [searchId, setSearchId] = useState(queryParams.id || "");
  const [deleteUserByAdmin, { deleteUserData, deleteUserloading, deleteUserError, reset }] = useMutation(DELETE_USER_BY_ADMIN);
  function addParam(key, value) {
    let params = queryString.parse(props.location.search);
    params[key] = value;
    if (key === "id" || key === "keyword") {
      delete params.page;
      if (key === "id") {
        delete params.keyword;
      } else {
        delete params.id;
      }
    }
    props.history.push({ search: "?" + queryString.stringify(params) });
  }

  function handleKeywordSearch(e) {
    e.preventDefault();
    setSearchId("");
    props.history.push({ search: "?keyword=" + keyword });
  }
  function onKeywordSearchInput(e) {
    setKeyword(e.target.value);
  }
  function handleIdSearch(e) {
    e.preventDefault();
    setKeyword("");
    props.history.push({ search: "?id=" + searchId });
  }
  function onIdSearchInput(e) {
    setSearchId(e.target.value);
  }

  return (
    <Layout>
      <div className="container-fluid">
        <h1> Cleaners</h1>
        {loading && <p>LOADING</p>}
        {error && <p>ERROR</p>}
        {data && (
          <div>
            <div className="row">
              <form className="col form-inline" onSubmit={handleIdSearch}>
                <div className="form-group mx-sm-3 mb-2">
                  <input
                    id="search"
                    onChange={onIdSearchInput}
                    name="search"
                    value={searchId}
                    placeholder="Order/Customer ID"
                    className="form-control"
                    type="text"
                  />
                </div>
                <button className="btn btn-primary mb-2" type="submit">
                  ID Search
                </button>
              </form>
              <form className="col form-inline" onSubmit={handleKeywordSearch}>
                <div className="form-group mx-sm-3 mb-2">
                  <input
                    id="search"
                    onChange={onKeywordSearchInput}
                    name="search"
                    value={keyword}
                    className="form-control"
                    type="text"
                    placeholder="Email/Phone#/Name"
                  />
                </div>
                <button className="btn btn-primary mb-2" type="submit">
                  Keyword Search
                </button>
              </form>
            </div>

            {data.cleaners.length ? (
              <div>
                <table className="table table-hover text-center">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone#</th>
                      <th scope="col">Account Status</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Edit</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.cleaners &&
                      data.cleaners.map((user) => (
                          <tr key={user.id}>
                            <th scope="row">{user.id.slice(-8)}</th>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.email}</td>
                            <td>{user.phoneNumber}</td>
                            <td>{user.status}</td>
                            <td>
                              {moment
                                  .unix(user.createdAt / 1000)
                                  .format("h:mm A, MM/DD/YYYY")}
                            </td>

                            <td>
                              <ConfirmModal
                                  title='Delete cleaner'
                                  type='delete-icon-link'
                                  icon='fa fa-edit fa-2x'
                                  des={'delete cleaner'}
                                  link={'/dashboard/cleaner/' + user.id}
                                  id={user.id}
                              />
                            </td>
                            <td>
                              <ConfirmModal
                                  title="Delete cleaner"
                                  type="delete-icon"
                                  icon="fa fa-trash fa-2x"
                                  des='Are you certain you wish to proceed with deletion?'
                                  id={user.id}
                                  handleSubmit={() => {
                                    deleteUserByAdmin({variables: {"id": user.id}}).then(()=>{
                                      // setUpdate("user deleted")
                                      window.location.reload(); // todo: remove with component refresh
                                    });
                                  }}
                              />
                            </td>


                            {/*<td>*/}
                            {/*  <a href={`/dashboard/cleaner/${user.id}`}>*/}
                            {/*    <i className="fas fa-edit fa-2x"></i>*/}
                            {/*  </a>*/}
                            {/*</td>*/}
                          </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
                <p>No more cleaner records</p>
            )}
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li className="page-item">
                  <button
                      hidden={queryParams.page <= 1}
                      onClick={() => {
                        if (!queryParams.page) {
                          queryParams.page = 1;
                        }
                        const nextPage = +queryParams.page - 1;
                        addParam("page", nextPage);
                      }}
                      className="page-link"
                  >
                    Previous
                  </button>
                </li>
                <li className="page-item">
                  <button
                      hidden={data.cleaners.length !== 10}
                      onClick={() => {
                        if (!queryParams.page) {
                          queryParams.page = 1;
                        }
                        const nextPage = +queryParams.page + 1;
                      addParam("page", nextPage);
                    }}
                    className="page-link"
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </Layout>
  );
};
export default Cleaners;
