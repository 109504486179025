import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const PicturesCard = props => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const pictures = props.pictures;
  const prefix = "https://storage.googleapis.com/vancleaner/";
  const length = pictures.length;
  return (
    <div className="mt-3  text-center">
      <button
        className="btn btn-lg block viewButton"
        style={{ color: "#30b5ab", outline: "none", boxShadow: "none" }}
        type="button"
        onClick={() => setIsOpen(true)}
      >
        <h4 className=" ">VIEW PICTURES</h4>
      </button>
      {isOpen && (
        <Lightbox
          mainSrc={`${prefix}${pictures[photoIndex]}`}
          nextSrc={`${prefix}${pictures[(photoIndex + 1) % length]}`}
          prevSrc={`${prefix}${pictures[(photoIndex + length - 1) % length]}`}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + length - 1) % length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % length)}
        />
      )}
    </div>
  );
};
export default PicturesCard;
